import React, { useState, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import axios from "axios";
import { LanguageContext } from "../../components/LanguageContext";

const ManageUserStoresPage = () => {
    const { translations } = useContext(LanguageContext) || {};
    const { roleName } = useParams();
    const { userId } = useParams();
    const company_id = localStorage.getItem("company_id");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const addUser = (id) => {
        const formData = new FormData();
        formData.append("store_id", id);
        formData.append("user_id", userId);

        addUserToServer(formData);
    };

    const removeUser = (id) => {
        const formData = new FormData();
        formData.append("store_id", id);
        formData.append("user_id", userId);

        removeUserFromServer(formData);
    };

    const addUserToServer = async (formData) => {
        try {
            setLoading(false);
            await axios
                .post("https://scmapi.kapromes.com/api/add-user-to-store", formData, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((res) => {
                    setLoading(true);
                    Swal.fire({
                        title: translations.adminManageUserStoresPageSuccess,
                        text: res.data.message,
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    window.location.reload();
                });
        } catch (error) {
            console.error("Error uploading data:", error);
            Swal.fire({
                title: translations.adminManageUserStoresPageErrorMessage,
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    const removeUserFromServer = async (formData) => {
        try {
            setLoading(false);
            await axios
                .post("https://scmapi.kapromes.com/api/remove-user-from-store", formData, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((res) => {
                    setLoading(true);
                    Swal.fire({
                        title: translations.adminManageUserStoresPageSuccess,
                        text: res.data.message,
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    window.location.reload();
                });
        } catch (error) {
            console.error("Error uploading data:", error);
            Swal.fire({
                title: translations.adminManageUserStoresPageErrorMessage,
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 5, display: "flex", flexDirection: "column" }}>
                        <h1>{translations.adminManageUserStoresPageTitle}</h1>
                        <div style={{ height: 400, width: "100%" }}>
                            {/* Your DataGrid or other components go here */}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ManageUserStoresPage;
