import React from 'react';
import {Navigate, Outlet} from "react-router";
import AdministratorLayout from "../layout/AdministratorLayout";
const AdministratorRoute = () => {
    const role = localStorage.getItem("role");

    if (role != "Administrator") {
        return <Navigate to="/unauthorized"/>;
    }

    return (
        <AdministratorLayout>
            <Outlet/>
        </AdministratorLayout>
    );
};

export default AdministratorRoute;