import React, {useContext, useState} from 'react';
import {Alert, Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {useParams} from "react-router";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createOrder, getOrder, updateHandKolicina} from "../../api/orderApi";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../utils/tableLang";
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import {LanguageContext} from '../../components/LanguageContext';
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import UpdateButtons from "../../components/UpdateButtons";

const PoslovodjaAcquisitionPage = () => {
    const tableLang = useTableLang();
    const {translations} = useContext(LanguageContext) || {};
    const {storeId, excelId} = useParams();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [value, setValue] = useState(0);
    const [productCode, setProductCode] = useState(null);
    const [week, setWeek] = useState(null);
    const [loading, setLoading] = useState(false);

    const queryClient = useQueryClient();

    const {data, isLoading} = useQuery({
        queryKey: ["orderGet", excelId],
        queryFn: () => getOrder(excelId),
    });

    const mutation = useMutation({
        mutationFn: (formData) => updateHandKolicina(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["orderGet"]);
            setSuccess(response.data.message);
            setError("");
        },
        onError: (error) => {
            setSuccess("");
            setError(error.response.data.message);
        }
    });

    const createOrderMutation = useMutation({
        mutationFn: (formData) => createOrder(formData),
        onSuccess: (response) => {
            setSuccess(response.data.message);
            setError("");
            setLoading(false);
        },
        onError: (error) => {
            setSuccess("");
            setError(error.response.data.message);
        }
    });

    const handleUpdate = (e, product_code, w) => {
        setValue(e.target.value);
        setProductCode(product_code);
        setWeek(w);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        if (value <= 0) {
            setSuccess("");
            setError("Broj komada mora biti veći od nule!");
            return;
        }

        if (productCode == null) {
            setSuccess("");
            setError("Šifra proizvoda ne sme biti prazna!");
            return;
        }

        if (week == null) {
            setSuccess("");
            setError("Nedelja mora da bude odabrana!");
            return;
        }

        formData.append('value', value);
        formData.append('calc_id', excelId);
        formData.append('product_code', productCode);
        formData.append('store_code', storeId);
        formData.append('week', week);

        mutation.mutateAsync(formData);
    }

    const handleCreateOrder = (e) => {
        setLoading(true);
        e.preventDefault();
        const formData = new FormData();

        formData.append('calc_id', excelId);
        formData.append('store_code', storeId);

        createOrderMutation.mutateAsync(formData);
    };

    const columns = [
        {
            field: "id",
            headerName: translations.adminAcquisitionPageColumnID,
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "code",
            headerName: translations.adminAcquisitionPageColumnProductCode,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "name",
            headerName: translations.adminAcquisitionPageColumnProductName,
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "needed",
            headerName: translations.adminAcquisitionPageColumnNeeded,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                <span>{parseFloat(params.row.needed).toFixed(0)}</span>
            ),
        },
        {
            field: "amount_on_storage",
            headerName: translations.adminAcquisitionPageColumnOnStock,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "amount_to_get",
            headerName: translations.adminAcquisitionPageColumnToAcquire,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => (
                <span>{parseFloat(params.row.amount_to_get).toFixed(0)}</span>
            ),
        },
        {
            field: "hand_amount",
            headerName: translations.adminAcquisitionPageColumnManualInputAmount,
            align: "left",
            headerAlign: "center",
            sortable: false,
            filterable: false,
            minWidth: 250,
            flex: 2,
            renderCell: (params) => {
                return (
                    <Box sx={{mt: 1, display: 'flex', flexDirection: 'row'}}>
                        <Box component="form"
                             onSubmit={handleSubmit}
                             noValidate sx={{mr: 1}}>
                            <TextField defaultValue={params.row.hand_amount.toFixed(0)}
                                       size="normal" id="hand_amount"
                                       label={translations.adminAcquisitionPageLabelManualInputAmount}
                                       variant="filled"
                                       onChange={(e) => handleUpdate(e, params.row.code)}
                            />
                        </Box>
                    </Box>
                );
            }
        },
    ];

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/poslovodja/narudzbine/kalkulacija/${excelId}`}
                            fullWidth
                        >
                            <FolderCopyIcon sx={{mr: 1}}/>
                            {translations.createdOrders}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <UpdateButtons
                            calc_id={excelId}
                            store_code={storeId}
                            success={success}
                            setSuccess={setSuccess}
                            error={error}
                            setError={setError}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        {success === "" ? null : (
                            <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                                {success}
                            </Alert>
                        )}
                        {error === "" ? null : (
                            <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                                {error}
                            </Alert>
                        )}
                        <div style={{height: 900, width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoading ? [] : data.data}
                                    columns={columns}
                                    slots={{toolbar: GridToolbar}}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                        <Button
                            onClick={handleCreateOrder}
                            variant="contained"
                            fullWidth
                            color="secondary"
                            sx={{mt: 2}}
                            disabled={loading}
                        >
                            {loading ? (
                                <CircularProgress
                                    size={32}
                                    color="primary"
                                    thickness={5}
                                    sx={{mr: 2}}
                                />
                            ) : (
                                <ListAltTwoToneIcon sx={{mr: 1}}/>
                            )}
                            <Typography sx={{fontWeight: "bold", fontSize: 15}}>
                                {translations.poslovodjaAcquisitionPageCreateOrderButton}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PoslovodjaAcquisitionPage;