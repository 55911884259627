import React, {useState, useContext} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {Alert, Button, CircularProgress, Typography} from "@mui/material";
import {useTableLang} from "../../utils/tableLang";
import OnlinePredictionTwoToneIcon from "@mui/icons-material/OnlinePredictionTwoTone";
import InventoryIcon from "@mui/icons-material/Inventory";
import AbcTwoToneIcon from "@mui/icons-material/AbcTwoTone";
import {useParams} from "react-router";
import {useQuery} from "react-query";
import UpdateButtons from "../../components/UpdateButtons";
import {getRop} from "../../api/ropApi";
import { LanguageContext } from "../../components/LanguageContext";

const AnalysisPage = () => {
    const tableLang = useTableLang();
    const { storeId, excelId } = useParams();
    const { translations } = useContext(LanguageContext) || {};

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const { data, isLoading } = useQuery({
        queryKey: ["ropGet", excelId],
        queryFn: () => getRop(excelId),
    });

    const columns = [
        {
            field: "code",
            headerName: translations.adminAnalysisProductCode || "Šifra proizvoda",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations.adminAnalysisProductName || "Naziv proizvoda",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "safety_storage",
            headerName: translations.adminAnalysisSafetyStock || "Sigurnosna zaliha",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>
                    {parseFloat(params.row.safety_storage).toFixed(2)}
                </Typography>
            ),
        },
        {
            field: "rop",
            headerName: translations.adminAnalysisROP || "ROP",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>{parseFloat(params.row.rop).toFixed(2)}</Typography>
            ),
        },
    ];

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {success === "" ? null : (
                <Alert severity="success" sx={{ mb: 2, justifyContent: "center" }}>
                    {translations.adminAnalysisSuccess || "Operation successful"}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{ mb: 2, justifyContent: "center" }}>
                    {translations.adminAnalysisError || "An error occurred"}
                </Alert>
            )}

            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/abc-ved`}
                        fullWidth
                    >
                        <AbcTwoToneIcon sx={{ mr: 1 }} />
                        {translations.adminAnalysisAbcVed || "ABC-VED"}
                    </Button>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}`}
                        fullWidth
                    >
                        <OnlinePredictionTwoToneIcon sx={{ mr: 1 }} />
                        {translations.adminAnalysisForecast || "Forecast"}
                    </Button>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/nabavka`}
                        fullWidth
                    >
                        <InventoryIcon sx={{ mr: 1 }} />
                        {translations.adminAnalysisProcurement || "Procurement"}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 5, display: "flex", flexDirection: "column" }}>
                        <UpdateButtons
                            calc_id={excelId}
                            store_code={storeId}
                            success={success}
                            setSuccess={setSuccess}
                            error={error}
                            setError={setError}
                        />
                        <div style={{ height: 600, width: "100%" }}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoading ? [] : data.data}
                                    columns={columns}
                                    slots={{ toolbar: GridToolbar }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 25 },
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AnalysisPage;
