import React, {useContext} from 'react';
import {useParams} from "react-router";
import {Avatar, Box, CircularProgress, Container, Divider, Grid, ListItem, ListItemAvatar, Paper} from "@mui/material";
import {useQuery} from "react-query";
import {getOrderInfo} from "../../api/orderApi";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from '@mui/icons-material/Folder';
import {LanguageContext} from "../../components/LanguageContext";

const PoslovodjaCreatedOrdersPage = () => {
    const {translations} = useContext(LanguageContext) || {};
    const {calcId} = useParams();

    const {data, isLoading} = useQuery({
        queryKey: ["getOrderInfo", calcId],
        queryFn: () => getOrderInfo(calcId),
    });

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <div style={{width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <Box>
                                    <Box sx={{p: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                                        <p>{translations.poslovodjaCreatedOrdersInfo || "Informacije o narudžbenici"}</p>
                                        <p style={{
                                            fontSize: 16,
                                            fontWeight: 'bold'
                                        }}>
                                            {translations.poslovodjaCreatedOrdersTime || "Vreme"}: {(new Date((JSON.parse(data?.data.info.order_response).date))).toLocaleString('sr-RS', {timeZoneName: "short"})}
                                        </p>
                                        <p style={{
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }}>
                                            {translations.poslovodjaCreatedOrdersId || "ID"}: {(JSON.parse(data?.data.info.order_response).processId)}
                                        </p>
                                        <p style={{
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }}>
                                            {translations.poslovodjaCreatedOrdersCode || "Kod"}: {(JSON.parse(data?.data.info.order_response).accountCode)}
                                        </p>
                                    </Box>
                                    <Divider/>
                                    <Box sx={{p: 2, height: 300, overflowY: 'scroll'}}>
                                        <List dense={true}>
                                            {JSON.parse(data?.data.info.order_response).order_items.map((item, index) => (
                                                <ListItem key={index}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <FolderIcon/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={item.productCode}
                                                        secondary={item.quantity}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </Box>
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PoslovodjaCreatedOrdersPage;
