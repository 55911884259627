import React, {useContext, useState} from 'react';
import {useParams} from "react-router";
import {LanguageContext} from "../../../components/LanguageContext";
import {useTableLang} from "../../../utils/tableLang";
import {useQuery} from "react-query";
import {getOrderByStore} from "../../../api/orderApi";
import {Alert, Button, CircularProgress} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';

const OrdersByStorePage = () => {
    let {storeId} = useParams();
    const {translations} = useContext(LanguageContext);
    const tableLang = useTableLang();

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data, isLoading, isError} = useQuery({
        queryKey: ['getOrderByStore', storeId],
        queryFn: () => getOrderByStore(storeId)
    });

    const columns = [
        {
            field: "id",
            headerName: translations.adminAcquisitionPageColumnID,
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations.adminUserProfileName,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "surname",
            headerName: translations.adminUserProfileSurname,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "id_calculation",
            headerName: translations.calculationCode,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "timestamp",
            headerName: translations.time,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "informacije",
            headerName: translations.information,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        href={`/administrator/narudzbina/` + params.row.id}
                        fullWidth
                    >
                        <InfoIcon sx={{mr: 1}}/>
                        {translations.information}
                    </Button>
                )
            }
        }
    ];

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column", height: 600}}>
                        {success === "" ? null : (
                            <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                                {success}
                            </Alert>
                        )}
                        {error === "" ? null : (
                            <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                                {error}
                            </Alert>
                        )}
                        {isLoading ? (
                            <CircularProgress/>
                        ) : (
                            <DataGrid
                                rows={isLoading ? [] : data?.data}
                                columns={columns}
                                slots={{toolbar: GridToolbar}}
                                initialState={{
                                    pagination: {
                                        paginationModel: {pageSize: 25},
                                    },
                                }}
                                localeText={tableLang}
                                pageSizeOptions={[25, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OrdersByStorePage;