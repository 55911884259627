import React, { useContext } from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartTwoToneIcon from "@mui/icons-material/ShoppingCartTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import WarehouseTwoToneIcon from "@mui/icons-material/WarehouseTwoTone";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import AnnouncementTwoToneIcon from "@mui/icons-material/AnnouncementTwoTone";
import { Box, Link } from "@mui/material";
import { LanguageContext } from "./LanguageContext";

const AdministratorMenu = () => {
    const { translations } = useContext(LanguageContext) || {};

    return (
        <React.Fragment>
            <ListItemButton to="/administrator/pocetna">
                <ListItemIcon>
                    <DashboardTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={translations?.adminAdministratorMenuHome || "Home"} />
            </ListItemButton>
            <ListItemButton to="/administrator/prodavnice">
                <ListItemIcon>
                    <ShoppingCartTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={translations?.adminAdministratorMenuStores || "Stores"} />
            </ListItemButton>
            <ListItemButton to="/administrator/korisnici">
                <ListItemIcon>
                    <PeopleAltTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={translations?.adminAdministratorMenuUsers || "Suppliers"}/>
            </ListItemButton>
            <ListItemButton to="/administrator/dobavljaci">
                <ListItemIcon>
                    <LocalShippingTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={translations?.adminAdministratorMenuSuppliers || "Suppliers"} />
            </ListItemButton>
            <ListItemButton to="/administrator/obavestenja">
                <ListItemIcon>
                    <AnnouncementTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary={translations?.adminAdministratorMenuNotifications || "Notifications"} />
            </ListItemButton>
            <Box sx={{ textAlign: "center", mt: "75%", background: "#093170", p: 2 }}>
                <Link href="https://www.semenarna.si/" target="_blank">
                    <img src={require("../assets/semenarna.png")} alt="Semenarna" />
                </Link>
            </Box>
        </React.Fragment>
    );
};

export default AdministratorMenu;
