import { Typography, Link } from "@mui/material";
import React, {useContext} from "react";
import {LanguageContext} from "./LanguageContext";

const Copyright = (props) => {

  const { translations } = useContext(LanguageContext);
  return (
      <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          {...props}
      >
          {translations.adminCopyrightAllRightsReserved}{" © "}
        <Link color="inherit" href="http://kala.rs/">
          {translations.adminCopyrightCompany}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
        <br />
        {translations.adminCopyrightEdition}
      </Typography>
  );
};

export default Copyright;
