import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState, useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { calculateABCVED } from "../api/abcvedApi";
import CalculateTwoToneIcon from "@mui/icons-material/CalculateTwoTone";
import { calculateOrder } from "../api/orderApi";
import { calculateRop } from "../api/ropApi";
import { LanguageContext } from "./LanguageContext";

const UpdateButtons = ({
                           calc_id,
                           store_code,
                           success,
                           setSuccess,
                           error,
                           setError,
                       }) => {
    const queryClient = useQueryClient();
    const { translations } = useContext(LanguageContext) || {};

    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const mutationAbcVed = useMutation({
        mutationFn: (formData) => calculateABCVED(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["getabcved"]);
            setSuccess(translations?.adminUpdateButtonsSuccessAbcVed || "Successfully calculated ABC VED!");
            setLoading1(false);
        },
        onError: (error) => {
            queryClient.invalidateQueries(["getabcved"]);
            setError(translations?.adminUpdateButtonsErrorAbcVed || "Failed to calculate ABC VED!");
            setLoading1(false);
        },
    });

    const mutationRop = useMutation({
        mutationFn: (formData) => calculateRop(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["ropGet"]);
            setSuccess(translations?.adminUpdateButtonsSuccessAnalysis || "Successfully calculated the analysis!");
            setLoading2(false);
        },
        onError: (error) => {
            queryClient.invalidateQueries(["ropGet"]);
            setError(translations?.adminUpdateButtonsErrorAnalysis || "Failed to calculate the analysis!");
            setLoading2(false);
        },
    });

    const mutationOrder = useMutation({
        mutationFn: (formData) => calculateOrder(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["orderGet"]);
            setSuccess(translations?.adminUpdateButtonsSuccessOrder || "Successfully calculated the order!");
            setLoading3(false);
        },
        onError: (error) => {
            queryClient.invalidateQueries(["orderGet"]);
            setError(translations?.adminUpdateButtonsErrorOrder || "Failed to calculate the order!");
            setLoading3(false);
        },
    });

    const submitAbcVed = () => {
        const formData = new FormData();
        formData.append("calc_id", calc_id);
        formData.append("store_code", store_code);
        setLoading1(true);
        mutationAbcVed.mutateAsync(formData).catch((e) => {});
    };

    const submitRop = () => {
        const formData = new FormData();
        formData.append("calc_id", calc_id);
        formData.append("store_code", store_code);
        setLoading2(true);
        mutationRop.mutateAsync(formData).catch((e) => {});
    };

    const submitOrder = () => {
        const formData = new FormData();
        formData.append("calc_id", calc_id);
        formData.append("store_code", store_code);
        setLoading3(true);
        mutationOrder.mutateAsync(formData).catch((e) => {});
    };

    return (
        <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item lg={4} sm={12} xs={12}>
                <Button
                    onClick={submitAbcVed}
                    aria-label="add"
                    variant="contained"
                    disabled={loading1}
                    fullWidth
                    color="secondary"
                >
                    {loading1 ? (
                        <CircularProgress
                            size={32}
                            color="primary"
                            thickness={5}
                            sx={{ mr: 2 }}
                        />
                    ) : (
                        <CalculateTwoToneIcon sx={{ mr: 1 }} />
                    )}
                    <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                        {translations?.adminUpdateButtonsCalculateAbcVed || "Calculate ABC-VED"}
                    </Typography>
                </Button>
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
                <Button
                    onClick={submitRop}
                    variant="contained"
                    disabled={loading2}
                    fullWidth
                    color="secondary"
                >
                    {loading2 ? (
                        <CircularProgress
                            size={32}
                            color="primary"
                            thickness={5}
                            sx={{ mr: 2 }}
                        />
                    ) : (
                        <CalculateTwoToneIcon sx={{ mr: 1 }} />
                    )}
                    <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                        {translations?.adminUpdateButtonsCalculateAnalysis || "Calculate Analysis"}
                    </Typography>
                </Button>
            </Grid>
            <Grid item lg={4} sm={12} xs={12}>
                <Button
                    onClick={submitOrder}
                    variant="contained"
                    disabled={loading3}
                    fullWidth
                    color="secondary"
                >
                    {loading3 ? (
                        <CircularProgress
                            color="primary"
                            thickness={5}
                            sx={{ mr: 2 }}
                            size={32}
                        />
                    ) : (
                        <CalculateTwoToneIcon sx={{ mr: 1 }} />
                    )}
                    <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                        {translations?.adminUpdateButtonsCalculateOrder || "Calculate Order"}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
};

export default UpdateButtons;
