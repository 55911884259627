// src/components/LanguageContext.js

import React, { createContext, useState } from 'react';
import srTranslations  from '../assets/translations/sr.json'; // Ensure translations are imported correctly
import enTranslations  from '../assets/translations/en.json';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'sr');

    const switchLanguage = () => {
        const newLanguage = language === 'sr' ? 'en' : 'sr';
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    const translations = language === 'sr' ? srTranslations : enTranslations;

    return (
        <LanguageContext.Provider value={{ switchLanguage, translations }}>
            {children}
        </LanguageContext.Provider>
    );
};
