import { useContext } from 'react';
import { LanguageContext } from '../components/LanguageContext';

export const useTableLang = () => {
  const { translations } = useContext(LanguageContext) || {};

  return {
    noRowsLabel: translations.tableLangNoRowsLabel,
    noResultsOverlayLabel: translations.tableLangNoResultsOverlayLabel,

    // Density selector toolbar button text
    toolbarDensity: translations.tableLangToolbarDensity,
    toolbarDensityLabel: translations.tableLangToolbarDensityLabel,
    toolbarDensityCompact: translations.tableLangToolbarDensityCompact,
    toolbarDensityStandard: translations.tableLangToolbarDensityStandard,
    toolbarDensityComfortable: translations.tableLangToolbarDensityComfortable,

    // Columns selector toolbar button text
    toolbarColumns: translations.tableLangToolbarColumns,
    toolbarColumnsLabel: translations.tableLangToolbarColumnsLabel,

    // Filters toolbar button text
    toolbarFilters: translations.tableLangToolbarFilters,
    toolbarFiltersLabel: translations.tableLangToolbarFiltersLabel,
    toolbarFiltersTooltipHide: translations.tableLangToolbarFiltersTooltipHide,
    toolbarFiltersTooltipShow: translations.tableLangToolbarFiltersTooltipShow,
    toolbarFiltersTooltipActive: (count) =>
        translations.tableLangToolbarFiltersTooltipActive.replace("{count}", count),

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: translations.tableLangToolbarQuickFilterPlaceholder,
    toolbarQuickFilterLabel: translations.tableLangToolbarQuickFilterLabel,
    toolbarQuickFilterDeleteIconLabel: translations.tableLangToolbarQuickFilterDeleteIconLabel,

    // Export selector toolbar button text
    toolbarExport: translations.tableLangToolbarExport,
    toolbarExportLabel: translations.tableLangToolbarExportLabel,
    toolbarExportCSV: translations.tableLangToolbarExportCSV,
    toolbarExportPrint: translations.tableLangToolbarExportPrint,
    toolbarExportExcel: translations.tableLangToolbarExportExcel,

    // Columns panel text
    columnsPanelTextFieldLabel: translations.tableLangColumnsPanelTextFieldLabel,
    columnsPanelTextFieldPlaceholder: translations.tableLangColumnsPanelTextFieldPlaceholder,
    columnsPanelDragIconLabel: translations.tableLangColumnsPanelDragIconLabel,
    columnsPanelShowAllButton: translations.tableLangColumnsPanelShowAllButton,
    columnsPanelHideAllButton: translations.tableLangColumnsPanelHideAllButton,

    // Filter panel text
    filterPanelAddFilter: translations.tableLangFilterPanelAddFilter,
    filterPanelRemoveAll: translations.tableLangFilterPanelRemoveAll,
    filterPanelDeleteIconLabel: translations.tableLangFilterPanelDeleteIconLabel,
    filterPanelLogicOperator: translations.tableLangFilterPanelLogicOperator,
    filterPanelOperator: translations.tableLangFilterPanelOperator,
    filterPanelOperatorAnd: translations.tableLangFilterPanelOperatorAnd,
    filterPanelOperatorOr: translations.tableLangFilterPanelOperatorOr,
    filterPanelColumns: translations.tableLangFilterPanelColumns,
    filterPanelInputLabel: translations.tableLangFilterPanelInputLabel,
    filterPanelInputPlaceholder: translations.tableLangFilterPanelInputPlaceholder,

    // Filter operators text
    filterOperatorContains: translations.tableLangFilterOperatorContains,
    filterOperatorEquals: translations.tableLangFilterOperatorEquals,
    filterOperatorStartsWith: translations.tableLangFilterOperatorStartsWith,
    filterOperatorEndsWith: translations.tableLangFilterOperatorEndsWith,
    filterOperatorIs: translations.tableLangFilterOperatorIs,
    filterOperatorNot: translations.tableLangFilterOperatorNot,
    filterOperatorAfter: translations.tableLangFilterOperatorAfter,
    filterOperatorOnOrAfter: translations.tableLangFilterOperatorOnOrAfter,
    filterOperatorBefore: translations.tableLangFilterOperatorBefore,
    filterOperatorOnOrBefore: translations.tableLangFilterOperatorOnOrBefore,
    filterOperatorIsEmpty: translations.tableLangFilterOperatorIsEmpty,
    filterOperatorIsNotEmpty: translations.tableLangFilterOperatorIsNotEmpty,
    filterOperatorIsAnyOf: translations.tableLangFilterOperatorIsAnyOf,

    // Filter values text
    filterValueAny: translations.tableLangFilterValueAny,
    filterValueTrue: translations.tableLangFilterValueTrue,
    filterValueFalse: translations.tableLangFilterValueFalse,

    // Column menu text
    columnMenuLabel: translations.tableLangColumnMenuLabel,
    columnMenuShowColumns: translations.tableLangColumnMenuShowColumns,
    columnMenuManageColumns: translations.tableLangColumnMenuManageColumns,
    columnMenuFilter: translations.tableLangColumnMenuFilter,
    columnMenuHideColumn: translations.tableLangColumnMenuHideColumn,
    columnMenuUnsort: translations.tableLangColumnMenuUnsort,
    columnMenuSortAsc: translations.tableLangColumnMenuSortAsc,
    columnMenuSortDesc: translations.tableLangColumnMenuSortDesc,

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        translations.tableLangColumnHeaderFiltersTooltipActive.replace("{count}", count),
    columnHeaderFiltersLabel: translations.tableLangColumnHeaderFiltersLabel,
    columnHeaderSortIconLabel: translations.tableLangColumnHeaderSortIconLabel,

    // Rows selected footer text
    footerRowSelected: (count) =>
        translations.tableLangFooterRowSelected.replace("{count}", count),

    // Total row amount footer text
    footerTotalRows: translations.tableLangFooterTotalRows,

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        translations.tableLangFooterTotalVisibleRows
            .replace("{visibleCount}", visibleCount)
            .replace("{totalCount}", totalCount),

    // Checkbox selection text
    checkboxSelectionHeaderName: translations.tableLangCheckboxSelectionHeaderName,

    // Boolean cell text
    booleanCellTrueLabel: translations.tableLangBooleanCellTrueLabel,
    booleanCellFalseLabel: translations.tableLangBooleanCellFalseLabel,

    // Actions cell more text
    actionsCellMore: translations.tableLangActionsCellMore,

    // Column pinning text
    pinToLeft: translations.tableLangPinToLeft,
    pinToRight: translations.tableLangPinToRight,
    unpin: translations.tableLangUnpin,
  };
};
