import React, {useContext, useState} from 'react';
import {useParams} from "react-router";
import Grid from "@mui/material/Grid";
import {Alert, Button, CircularProgress} from "@mui/material";
import AbcTwoToneIcon from "@mui/icons-material/AbcTwoTone";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import OnlinePredictionTwoToneIcon from "@mui/icons-material/OnlinePredictionTwoTone";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import {useQuery} from "react-query";
import {getOrderByCalculation} from "../../../api/orderApi";
import {LanguageContext} from "../../../components/LanguageContext";
import {useTableLang} from "../../../utils/tableLang";
import InventoryIcon from "@mui/icons-material/Inventory";
import InfoIcon from '@mui/icons-material/Info';

const OrdersByCalculationPage = () => {
    let {storeId, excelId} = useParams();
    const {translations} = useContext(LanguageContext);
    const tableLang = useTableLang();

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data, isLoading, isError} = useQuery({
        queryKey: ['getOrdersByIdCalculation', excelId],
        queryFn: () => getOrderByCalculation(excelId)
    });

    const columns = [
        {
            field: "id",
            headerName: translations.adminAcquisitionPageColumnID,
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "timestamp",
            headerName: translations.time,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "informacije",
            headerName: translations.information,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        href={`/administrator/narudzbina/` + params.row.id}
                        fullWidth
                    >
                        <InfoIcon sx={{mr: 1}}/>
                        {translations.information}
                    </Button>
                )
            }
        }
    ];

    return (
        <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={2}>
                <Grid item xl={3} lg={3} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}`}
                        fullWidth
                    >
                        <OnlinePredictionTwoToneIcon sx={{mr: 1}}/>
                        {translations.adminAcquisitionPagePrognoza}
                    </Button>
                </Grid>
                <Grid item xl={3} lg={3} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/abc-ved`}
                        fullWidth
                    >
                        <AbcTwoToneIcon sx={{mr: 1}}/>
                        {translations.adminAcquisitionPageABC}
                    </Button>
                </Grid>
                <Grid item xl={3} lg={3} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/analiza`}
                        fullWidth
                    >
                        <LeaderboardIcon sx={{mr: 1}}/>
                        {translations.adminAcquisitionPageAnaliza}
                    </Button>
                </Grid>
                <Grid item xl={3} lg={3} xs={12}>
                    <Button
                        variant="contained"
                        size="large"
                        href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/nabavka`}
                        fullWidth
                    >
                        <InventoryIcon sx={{mr: 1}}/>
                        {translations["adminAbcVedPageAcquisition"]}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column", height: 400}}>
                        {success === "" ? null : (
                            <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                                {success}
                            </Alert>
                        )}
                        {error === "" ? null : (
                            <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                                {error}
                            </Alert>
                        )}
                        {isLoading ? (
                            <CircularProgress/>
                        ) : (
                            <DataGrid
                                rows={isLoading ? [] : data?.data}
                                columns={columns}
                                slots={{toolbar: GridToolbar}}
                                initialState={{
                                    pagination: {
                                        paginationModel: {pageSize: 25},
                                    },
                                }}
                                localeText={tableLang}
                                pageSizeOptions={[25, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OrdersByCalculationPage;