import React, { useContext } from "react";
import { Backdrop, Button, CircularProgress, Container, Grid, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTableLang } from "../../../utils/tableLang";
import { useQuery } from "react-query";
import { getUsers } from "../../../api/userApi";
import { LanguageContext } from "../../../components/LanguageContext";

const UsersPage = () => {
    const tableLang = useTableLang();
    const { translations } = useContext(LanguageContext) || {};

    const { data, isLoading, isError } = useQuery({
        queryKey: ["users"],
        queryFn: () => getUsers(),
    });

    const columns = [
        {
            field: "id",
            headerName: translations?.adminUsersPageID || "ID",
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations?.adminUsersPageName || "Name",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "surname",
            headerName: translations?.adminUsersPageSurname || "Surname",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "email",
            headerName: translations?.adminUsersPageEmail || "E-mail",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "role_id",
            headerName: translations?.adminUsersPageRole || "User Type",
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <p>
                    {params.row.role_id == 1 ? "Administrator" : "Manager"}
                </p>
            ),
        },
        {
            field: "info",
            headerName: translations?.adminUsersPageInfo || "Information",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    href={`/administrator/korisnik/${params.row.id}`}
                >
                    {translations?.adminUsersPageInfo || "Information"}
                </Button>
            ),
        },
    ];

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {isLoading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : null}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 5, display: "flex", flexDirection: "column" }}>
                        <Button
                            sx={{ mb: 2, mt: 2 }}
                            fullWidth
                            variant="contained"
                            color="primary"
                            href={`/administrator/novi-korisnik`}
                        >
                            {translations?.adminUsersPageNewManager || "New Manager"}
                        </Button>
                        <div style={{ height: 400, width: "100%" }}>
                            <DataGrid
                                rows={!isLoading ? data.data : []}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                localeText={tableLang}
                                pageSizeOptions={[5, 10]}
                                disableRowSelectionOnClick
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UsersPage;
