import axios from "axios";
import {BASE_PATH} from "../services/api";

const getStores = async () => {
    const response = axios.get(BASE_PATH + "/admin/get-stores", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    return response;
};

const fetchStores = async () => {
    const response = axios.get(BASE_PATH + "/admin/navigator/stores", {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    return response;
};

export const updateStoreActivity = async (formData) => {
    return axios.post(BASE_PATH + "/admin/update-store-activity", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    })
}

export {getStores, fetchStores};
