import React, { useEffect, useState, useContext } from "react";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import avatar from "../../../assets/user.png";
import CustomTabPanel from "../../../components/CustomTabPanel";
import PropTypes from "prop-types";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMutation, useQuery } from "react-query";
import { changePassword, fetchUserInfo } from "../../../api/userApi";
import { LanguageContext } from "../../../components/LanguageContext"; // Ensure this path is correct

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const UserProfile = () => {
    const { translations } = useContext(LanguageContext) || {};

    const [value, setValue] = useState(0);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [validationError, setValidationError] = useState("");
    const [mutationError, setMutationError] = useState("");
    const [mutationMessage, setMutationMessage] = useState("");

    const handleChangePassword = (e) => {
        e.preventDefault();

        if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
            setValidationError(translations?.adminUserProfileAllFieldsRequired || "All fields are required!");
            return;
        }

        if (newPassword !== confirmPassword) {
            setValidationError(translations?.adminUserProfilePasswordMismatch || "New password and confirmation password must match!");
            return;
        }
        const formData = new FormData(e.target);
        formData.append("id_user", localStorage.getItem("user_id"));

        try {
            mutation.mutateAsync(formData).catch(() => {});
        } catch (error) {
            // do nothing
        }
    };

    const { data, isLoading, isError } = useQuery({
        queryKey: ["userId"],
        queryFn: () => fetchUserInfo(),
    });

    const mutation = useMutation({
        mutationFn: (formData) => changePassword(formData),
        onSuccess: (resp) => {
            setMutationMessage(resp.data.message);
        },
        onError: (error) => {
            setMutationError(error.data.message);
        },
    });

    useEffect(() => {
        if (!isLoading && !isError) {
            setName(data.data.name);
            setSurname(data.data.surname);
            setEmail(data.data.email);
            setUsername(data.data.username);
            setRole(data.data.role_id === 1 ? translations?.adminUserProfileAdmin || "Administrator" : translations?.adminUserProfileManager || "Manager");
        }
    }, [isLoading, isError, data, translations]);

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 5, display: "flex", flexDirection: "column" }}>
                        {isError ? (
                            <Alert severity="error">
                                {translations?.adminUserProfileFetchError || "Error fetching user data!"}
                            </Alert>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        justifyContent: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Avatar
                                        src={avatar}
                                        sx={{ width: 128, height: 128, alignSelf: "center" }}
                                    />
                                    <Typography sx={{ mt: 2, alignSelf: "center" }}>
                                        {isLoading ? <CircularProgress /> : role}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{ textAlign: "center" }}>
                                    <Typography sx={{ p: 2 }}>
                                        {translations?.adminUserProfileName || "Name"}: {isLoading ? <CircularProgress /> : name}
                                    </Typography>
                                    <Divider />
                                    <Typography sx={{ p: 2 }}>
                                        {translations?.adminUserProfileSurname || "Surname"}: {isLoading ? <CircularProgress /> : surname}
                                    </Typography>
                                    <Divider />
                                    <Typography sx={{ p: 2 }}>
                                        {translations?.adminUserProfileUsername || "Username"}: {isLoading ? <CircularProgress /> : username}
                                    </Typography>
                                    <Divider />
                                    <Typography sx={{ p: 2 }}>
                                        {translations?.adminUserProfileEmail || "Email address"}: {isLoading ? <CircularProgress /> : email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}

                        <Divider />
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab label={translations?.adminUserProfileChangePassword || "Change Password"} {...a11yProps(0)} />
                                <Tab label={translations?.adminUserProfileSupport || "Support"} {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {validationError !== "" ? (
                                <Alert sx={{ justifyContent: "center" }} severity="warning">
                                    {validationError}
                                </Alert>
                            ) : null}
                            {mutation.isError ? (
                                <Alert
                                    sx={{ width: "100%", justifyContent: "center" }}
                                    severity="error"
                                >
                                    {mutationError}
                                </Alert>
                            ) : null}
                            {mutation.isSuccess ? (
                                <Alert
                                    sx={{ width: "100%", justifyContent: "center" }}
                                    severity="success"
                                >
                                    {mutationMessage}
                                </Alert>
                            ) : null}
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleChangePassword}
                                sx={{ mt: 1 }}
                            >
                                <FormControl sx={{ width: "100%" }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        🔏 {translations?.adminUserProfileOldPassword || "Old Password"}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword1 ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword1}
                                                    onMouseDown={handleMouseDownPassword1}
                                                    edge="end"
                                                >
                                                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        required={true}
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        label={translations?.adminUserProfileOldPassword || "🔏 Old Password"}
                                        name="old_password"
                                    />
                                </FormControl>
                                <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        🔏 {translations?.adminUserProfileNewPassword || "New Password"}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword2 ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword2}
                                                    onMouseDown={handleMouseDownPassword2}
                                                    edge="end"
                                                >
                                                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        required={true}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        label={translations?.adminUserProfileNewPassword || "🔏 New Password"}
                                        name="new_password"
                                    />
                                </FormControl>
                                <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">
                                        🔏 {translations?.adminUserProfileConfirmNewPassword || "Confirm New Password"}
                                    </InputLabel>
                                    <OutlinedInput
                                        type="password"
                                        id="outlined-adornment-password"
                                        required={true}
                                        label={translations?.adminUserProfileConfirmNewPassword || "🔏 Confirm New Password"}
                                        name="confirm_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {translations?.adminUserProfileChangePassword || "Change Password"}
                                </Button>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <TextField
                                    rows={3}
                                    fullWidth
                                    multiline
                                    id="outlined-basic"
                                    label={translations?.adminUserProfileMessage || "Message"}
                                    variant="outlined"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {translations?.adminUserProfileSendMessage || "Send Message"}
                                </Button>
                            </Box>
                        </CustomTabPanel>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default UserProfile;
