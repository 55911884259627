import React, { useContext } from "react";
import { Alert, CircularProgress, Container, Grid, Paper } from "@mui/material";
import { useQuery } from "react-query";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTableLang } from "../../utils/tableLang";
import { getLogs } from "../../api/logsApi";
import { LanguageContext } from "../../components/LanguageContext"; // Adjust the path as needed

const columns = (translations) => [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "message",
        headerName: translations.adminLogsPageMessage,
        minWidth: 250,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "type",
        headerName: translations.adminLogsPageNotificationType,
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
        sortable: false,
        hideable: false,
    },
    {
        field: "fail_success",
        headerName: translations.adminLogsPageSuccess,
        minWidth: 200,
        flex: 1,
        align: "center",
        headerAlign: "center",
        sortable: false,
        filterable: false,
        hideable: false,
        renderCell: (params) => {
            return (
                <Alert
                    variant="filled"
                    severity={params.value == 1 ? "success" : "error"}
                >
                    {params.value == 1 ? translations.adminLogsPageSuccessStatus : translations.adminLogsPageErrorStatus}
                </Alert>
            );
        },
    },
];

const LogsPage = () => {
    const tableLang = useTableLang();
    const { translations } = useContext(LanguageContext) || {};
    const { data, isLoading, isError } = useQuery({
        queryKey: ["logs"],
        queryFn: () => getLogs(),
    });

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 5, display: "flex", flexDirection: "column" }}>
                        <div style={{ height: 500, width: "100%" }}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ) : (
                                <DataGrid
                                    slots={{ toolbar: GridToolbar }}
                                    rows={data?.data || []}
                                    columns={columns(translations)}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 25 },
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LogsPage;
