import React from 'react';

const OrdersPage = () => {
    return (
        <div>

        </div>
    );
};

export default OrdersPage;